import { useQuery } from "@tanstack/react-query";
import Header from "../components/Header"
import Login from "../components/Login"
import { CognitoService } from "../services";

const cognitoService = new CognitoService();

const cognitoSignOut = async () => {
  try {
      return await cognitoService.signOut();
  } catch (error) {
      console.log('error signing out:', error);
      return null;
  }
}

export default function LoginPage(props: { logout?: boolean }) {
  const { error, isLoading } = useQuery({
    queryKey: ['repoData'],
    queryFn: () => cognitoSignOut(),
    enabled: props.logout || false,
  });

  if (error) {
    return <>{error}</>;
  }

  if (props.logout && isLoading) {
    return <>loading...</>;
  }

  return (
    <>
      <Header
        heading="Ingresa"
        paragraph="No tenes cuenta? "
        linkName="Registrate"
        linkUrl="/signup"
      />
      <Login />
    </>
  )
}
