import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginFields } from "../constants/formFields";
import { CognitoService } from '../services';
import { ForecastService } from '../services/ForecastService';
import { MatchService } from '../services/MatchService';
import { StatsService } from '../services/StatsService';
import { useAuthStore } from '../store';
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import MatchCard from './MatchCard';
import ResultsExtrasTable from './ResultsExtrasTable';
import ResultsTable from './ResultsTable';
import Spinner from './Spinner';

interface Props {
  isExtras?: boolean;
  extras: Array<{
    username: string;
    champion: string;
    topScorer: string;
  }>;
}

export default function ResultsExtrasContainer(props: Props) {
  const [isVisible, setIsVisible] = useState(true);

  const header = (
    <div className="text-center">
      Extras
    </div>
  );

  return (
    <>
      <h2 id="accordion-collapse-heading-1">
        <button
          type="button"
          onClick={() => setIsVisible(!isVisible)}
          className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
          <div id="tab" className="w-full flex py-1 justify-center">
            {header}
          </div>
          <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
      </h2>
      <div id="accordion-collapse-body-1" className={isVisible ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-1">
        <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
          <ResultsExtrasTable extras={props.extras} />
        </div>
      </div>
    </>
  );
}
