import Header from "../components/Header";
import SignUp from "../components/SignUp";

// const heading = "Signup to create an account";
// const paragraph = "Already have an account? ";

export default function SignUpPage() {
  return (
    <>
      <Header
        heading="Registrate"
        paragraph="Ya tenes cuenta? "
        linkName="Ingresa"
        linkUrl="/"
      />
      <SignUp />
    </>
  )
}
