import Header from "../components/Header";
import ProdeVoting from "../components/ProdeVoting";

export default function VotingPage() {
  return (
    <>
      <Header
        heading="Votacion"
        paragraph=""
        linkName=""
        linkUrl=""
      />
      <ProdeVoting />
    </>
  )
}
