import create from 'zustand';

/*
export const useAuthStore = create((set) => ({
  auth: {},
  setAuth: (auth) => set((state) => ({ auth })),
  clearAuth: () => set({ auth: {} }),
}));
*/

export const useAuthStore = create(set => ({
  auth: {},
  // votes: 3,
  // addVotes: () => set(state => ({ votes: state.votes + 1 })),
  // subtractVotes: () => set(state => ({ votes: state.votes - 1 })),
  // setVotes: () => set(state => ({ votes: state.votes + 1 })),
  setAuthData: (by) => set((state) => {
    return { auth: { ...by } };
  }),
}));
