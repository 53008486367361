import axios from "axios";
import { API_BASE_URL } from "../consts";

export class StatsService {
  public async getPositions(token: string) {
    try {
      const url = `${API_BASE_URL}/positions`;

      return axios.get(url, {
        headers: {
          'authorization': token,
        },
      });
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }
}
