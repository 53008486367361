import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow'
import { loginFields } from "../constants/formFields";
import { CognitoService } from '../services';
import { useAuthStore } from '../store';
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";

const cognitoService = new CognitoService();

const fields = loginFields;
let fieldsState = {} as LoginFields;
fields.forEach(field => fieldsState[field.id] = '');

interface LoginFields {
  emailAddress: string;
  password: string;
  token?: string;
}

const cognitoSignIn = async (loginState: LoginFields) => {
  const { emailAddress, password } = loginState;

  if (!emailAddress || !password) {
    return null;
  }

  return await cognitoService.signIn(emailAddress, password);
};

const getFullErrorMessage = (message: string) => {
  if (message === "UserNotConfirmedException") {
    return "Tenes que validar la cuenta haciendo click en el link que te llego por mail. Puede estar en spam.";
  }

  if (message === "NotAuthorizedException") {
    return "Nombre de usuario o password incorrectos";
  }

  return message;
}

export default function Login() {
  const navigate = useNavigate();
  const [loginState, setLoginState] = useState(fieldsState);
  const [errorMessage, setErrorMessage] = useState("");
  /// const [authData, setAuthData] = useState(undefined);
  // const setAuthDataStore = useAuthStore((state: any) => state.setAuth({ ...state }));

  const [auth, setAuthData] = useAuthStore(
    (state: any) => [state.auth, state.setAuthData],
    shallow,
  );
  
  const { isFetching, error, refetch } = useQuery({
    queryKey: ['repoData', loginState],
    queryFn: () => cognitoSignIn(loginState),
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      const token = data?.signInUserSession?.idToken?.jwtToken;
      // setAuthData(data);
      setAuthData(data);
      if (token) {
        navigate('/pronosticos', { state: { token } });
        // return <Navigate to="/voting" />
      }
    },
    onError(err) {
      setErrorMessage((err as Error).name);
    },
  });
  // const { data, isLoading, error, refetch} = useQuery(['getkurs', loginState], () => cognitoSignIn(loginState));

  /*
  if (loginState.token) {
    navigate('/voting', { state: { token: loginState.token } });
    // return <Navigate to="/voting" />
  }
  */

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    authenticateUser();
  }

  //Handle Login API Integration here
  const authenticateUser = () => {
    refetch();
  }

  // if (isLoading) return <>'Loading...'</>;

  // if (error) return <>An error has occurred: ${(error as any).message}</>;

  /*
  if (data) {
    console.log("Setting token");
    setLoginState({ ...loginState, token: data.signInUserSession.idToken.jwtToken });
  }
  */

  /*
  if (loginState.token) {
    console.log("Token", loginState.token);
    refetchPreferences();
  }
  */

  /*
  if (data) {
    return (
      <>
        TOKEN: {data.signInUserSession.idToken.jwtToken}
      </>
    );
  }
  */

  /*
  if (preferences) {
    return (
      <>
        TOKEN: {JSON.stringify(preferences)}
      </>
    ); 
  }
  */

  return (
    <div className="flex flex-col justify-center items-center">
      <form className="mt-8 space-y-6 w-full sm:w-1/2" onSubmit={handleSubmit}>
        <div className="-space-y-px">
          {
            fields.map(field =>
              <Input
                key={field.id}
                handleChange={handleChange}
                value={loginState[field.id]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                customClass=""
                srOnly={false}
              />

            )
          }
        </div>

        <FormExtra />
        <FormAction handleSubmit={handleSubmit} text="Login" isLoading={isFetching} />

        {errorMessage !== "" &&
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> {getFullErrorMessage(errorMessage)}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
            </span>
          </div>
        }
      </form>
    </div>
  )
}
