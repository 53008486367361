import { isDisabled } from "@testing-library/user-event/dist/utils";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Teams } from "../consts";

interface Team {
  id: number;
  name: string;
  ISOCode: string;
}

interface Props {
  selectedValue: string;
  isDisabled?: boolean;
  onChangeHandler: (teamId: string) => void;
}

export default function TeamSelect(props: Props) {
  return (
    <select
      defaultValue={props.selectedValue}
      value={props.selectedValue}
      id="countries"
      onChange={(e) => props.onChangeHandler(e.target.value)}
      disabled={props.isDisabled}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      <option value="-1">Equipo</option>
      {Teams.map((o: Team) => {
        return (
          <option key={`option-team-${o.id}`} value={o.ISOCode}>{o.name}</option>
        )
      })}
    </select>
  );
}
