import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginFields } from "../constants/formFields";
import { Teams } from '../consts';
import { CognitoService } from '../services';
import { ForecastService } from '../services/ForecastService';
import { MatchService } from '../services/MatchService';
import { StatsService } from '../services/StatsService';
import { useAuthStore } from '../store';
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import MatchCard from './MatchCard';
import ResultsExtrasTable from './ResultsExtrasTable';
import ResultsTable from './ResultsTable';
import Spinner from './Spinner';

interface Forecast {
  username: string;
  result: number;
}

interface Match {
  id: number;
  homeId: number;
  awayId: number;
  result?: number;
  forecasts: Array<Forecast>;
}

interface Props {
  matches: Array<Match>;
}

export default function ResultsContainer(props: Props) {
  const [matchVisible, setIsVisible] = useState(0);

  const matchHeader = (homeId: number, awayId: number) => {
    const home = Teams.find((o) => o.id === homeId);
    const away = Teams.find((o) => o.id === awayId);

    return (
      <div className="grid grid-cols-3 gap-4">
        <div className="text-center">
          <span className={`fi fi-${home?.ISOCode?.toLowerCase()}`}></span>&nbsp;
          <span className="block">{home?.name}</span>
        </div>
        <div className="text-center">vs</div>
        <div className="text-center">
          <span className={`fi fi-${away?.ISOCode.toLowerCase()}`}></span>&nbsp;
          <span className="block">{away?.name}</span>
        </div>
      </div>
    );
  };

  const matchTables = (id: number) => {
    return props.matches.filter((o) => o.id === id).map((o) => {
      return (
        <ResultsTable
          id={o.id}
          homeId={o.homeId}
          awayId={o.awayId}
          result={o.result}
          forecasts={o.forecasts}
        />
      );
    })
  };

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      {props.matches.map((o) => {
        return (
          <div key={`result-${o.id}`}>
            <h2 id={`accordion-collapse-heading-${o.id}`}>
              <button
                type="button"
                onClick={() => setIsVisible(o.id === matchVisible ? 0 : o.id)}
                className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white" data-accordion-target={`#accordion-collapse-body-${o.id}`} aria-expanded="true" aria-controls={`accordion-collapse-body-${o.id}`}>
                <div id="tab" className="w-full flex py-1 justify-center">
                  {matchHeader(o.homeId, o.awayId)}
                </div>
                <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
            </h2>
            <div id={`accordion-collapse-body-${o.id}`} className={matchVisible === o.id ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-1">
              <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                {matchTables(o.id)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
