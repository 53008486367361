import { useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { signupFields } from "../constants/formFields"
import FormAction from "./FormAction";
import Input from "./Input";
import { CognitoService } from '../services';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from "react-router-dom";

interface SignUpFields {
  username?: string;
  emailAddress: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
}

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',
    // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,
    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    signUpVerificationMethod: 'link', // 'code' | 'link' 
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    /*
    cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: '.yourdomain.com',
    // OPTIONAL - Cookie path
        path: '/',
    // OPTIONAL - Cookie expiration in days
        expires: 365,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        // QsameSite: "strict" | "lax",
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: true
    },
    */
    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    /*
   oauth: {
       domain: process.env.REACT_APP_COGNITO_DOMAIN_NAME,
       scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
       redirectSignIn: 'http://localhost:3000/',
       redirectSignOut: 'http://localhost:3000/',
       responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
   }
   */
  }
});

// You can get the current config object
Auth.configure();

const cognitoService = new CognitoService();

const fields = signupFields;
let fieldsState = {} as SignUpFields;

fields.forEach(field => fieldsState[field.id] = '');

export default function SignUp() {
  const [signUpState, setSignUpState] = useState(fieldsState);
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const cognitoSignUp = async (signUpFields: SignUpFields) => {
    return await cognitoService.signUp(signUpFields);
  }

  const mutation: any = useMutation({
    mutationFn: (signUpState: SignUpFields) => {
      return cognitoSignUp(signUpState);
    },
    onSuccess: () => {
      setShowModal(true);
    },
    onError(error) {
      setErrorMessage((error as Error).name);
    },
    retry: false,
  });

  const renderModal = () => {
    return (<>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Registracion
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    Registracion exitosa!
                  </p>
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    Para poder ingresar tenes que validar tu cuenta haciendo click en el link que te va a llegar por mail.
                  </p>
                  <p className="my-4 text-slate-500 text-lg leading-relaxed">
                    Importante leer el <Link to="/reglamento" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Reglamento</Link>.
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      navigate('/');
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
    );
  };

  const handleChange = (e) => setSignUpState({ ...signUpState, [e.target.id]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (signUpState.password !== signUpState.confirmPassword) {
      setErrorMessage("Passwords no coinciden.");
    } else {
      setErrorMessage("");
      createAccount();
    }
  }

  //handle Signup API Integration here
  const createAccount = () => {
    mutation.mutate(signUpState);
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <form className="mt-8 space-y-6 w-full sm:w-1/2" onSubmit={handleSubmit} autoComplete="off">
        <div>
          {mutation.isError ? (
            <div>An error occurred: {mutation.error.message}</div>
          ) : null}

          {mutation.isSuccess ? <div>Todo added!</div> : null}
        </div>
        <div className="pb-3">
          {
            fields.map(field =>
              <Input
                key={field.id}
                handleChange={handleChange}
                value={signUpState[field.id]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                customClass=""
                srOnly={false}
              />

            )
          }

          <FormAction handleSubmit={handleSubmit} text="Registrar" isLoading={mutation.isLoading} />

          {errorMessage !== "" &&
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <strong className="font-bold">Error!</strong>
              <span className="block sm:inline"> {errorMessage}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
              </span>
            </div>
          }
        </div>
      </form>
      {renderModal()}
    </div>
  )
}
