import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginFields } from "../constants/formFields";
import { CognitoService } from '../services';
import { ForecastService } from '../services/ForecastService';
import { MatchService } from '../services/MatchService';
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import MatchCard from './MatchCard';
import Button from './Button';
import LoadingButton from './LoadingButton';
import { useAuthStore } from '../store';
import Spinner from './Spinner';
import ExtrasCard from './ExtrasCard';
import ExtrasForecast from './ExtrasForecast';
import { ProdeService } from '../services/ProdeService';
import { Groups, SecondPhase } from '../consts';

const forecastService = new ForecastService();

const fields = loginFields;
let initialMatchState = [] as Array<any>;
// fields.forEach(field => fieldsState[field.id] = '');

/*
interface LoginFields {
  emailAddress: string;
  password: string;
  token?: string;
}
*/
interface ExtraForecasts {
  champion: string;
  topScorer: string;
}
interface Forecasts {
  matchId: number;
  resultId: number;
}

const initialForecasts = {} as Record<number, number>;

const getAllMatches = async (token: string, username: string) => {
  return await forecastService.getMatchesAndForecasts(token, username);
};

const sendForecasts = async (token: string, userId: string, forecasts: any) => {
  const matchIds = Object.keys(forecasts);
  const requestBody = [] as Array<any>;

  matchIds.forEach((matchId) => {
    requestBody.push({
      userId,
      matchId,
      result: forecasts[matchId] || null,
    });
  });

  return await forecastService.setForecasts(token, userId, requestBody);
};

const sendExtraForecasts = async (token: string, userId: string, forecasts: any) => {
  const types = Object.keys(forecasts);
  const requestBody = [] as Array<any>;

  types.forEach((type) => {
    requestBody.push({
      userId,
      type,
      result: forecasts[type] || null,
    });
  });

  return await forecastService.setExtraForecasts(token, userId, requestBody);
};

const teamIsDefined = (team: { ISOCode: string }) => team?.ISOCode && team?.ISOCode !== 'tbd';
const matchHasBothTeams = (match: any) => teamIsDefined(match.home) && teamIsDefined(match.away);

const initialSelectedGroup = 'EXTRAS';

export default function Matches() {
  const [matchState, setMatchState] = useState(initialMatchState);
  const [selectedGroup, setSelectedGroup] = useState(initialSelectedGroup);
  const [isExtrasVisible, setExtrasVisibility] = useState(selectedGroup === 'EXTRAS');
  const [forecasts, setForecasts] = useState(initialForecasts);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [extraForecasts, setExtraForecasts] = useState({ champion: "-1", topScorer: "" } as ExtraForecasts);

  const authData = useAuthStore((state: any) => state.auth);
  const token = authData?.signInUserSession?.idToken?.jwtToken;
  const username = authData?.attributes?.preferred_username;

  const { isFetching } = useQuery({
    queryKey: ['getAllMatches'],
    queryFn: () => getAllMatches(token, username),
    enabled: !!token, // true,
    retry: false,
    refetchOnMount: "always",
    staleTime: Infinity,
    onSuccess: (data) => {
      const extraForecasts = data?.data?.extraForecasts;
      setMatchState(data?.data?.matches);
      setExtraForecasts(extraForecasts);
      setForecasts(forecasts);

      if (extraForecasts.champion && extraForecasts.topScorer) {
        setSelectedGroup('Group A');
        setExtrasVisibility(false);
      }
    },
    onError(err) {
      setErrorMessage((err as Error).name);
    },
  });

  const forecastsMutation: any = useMutation({
    mutationFn: (forecasts: any) => {
      return sendForecasts(token, username, forecasts);
    },
    onSuccess: () => {
      const matchIds = Object.keys(forecasts);
      matchIds.forEach((matchId) => {
        const matchIndex = matchState.findIndex((o) => o.matchId === parseInt(matchId, 10));

        if (matchIndex >= 0) {
          matchState[matchIndex].result = forecasts[matchId];
        }
      });
      setMatchState(matchState);
      setSuccessMessage("Enviado!");
      setErrorMessage("");
    },
    onError(error) {
      console.error("Error", error);
      setErrorMessage((error as Error).message);
    },
  });

  const extrasMutation: any = useMutation({
    mutationFn: (forecasts: any) => {
      return sendExtraForecasts(token, username, forecasts);
    },
    onSuccess: () => {
      /*
      const matchIds = Object.keys(forecasts);
      matchIds.forEach((matchId) => {
        debugger;
        console.log("matchId", matchId);
        const matchIndex = matchState.findIndex((o) => o.matchId === parseInt(matchId, 10));

        console.log("matchIndex", matchIndex);

        if (matchIndex >= 0) {
          matchState[matchIndex].result = forecasts[matchId];
        }
      });
      console.log("matchState", matchState);
      setMatchState(matchState);
      */
      setSuccessMessage("Enviado!");
      setErrorMessage("");
    },
    onError(error) {
      console.error("Error", error);
      setErrorMessage((error as Error).message);
    },
  });

  if (!errorMessage && (isFetching || !matchState?.length)) {
    return <Spinner />;
  }

  const onClickTeamHandler = (matchId: number, resultId: number) => {
    setForecasts({ ...forecasts, [matchId]: resultId });
    return undefined;
  }

  const renderVisibleGames = () => {
    return (matchState || []).filter((o) => o.round === selectedGroup && matchHasBothTeams(o)).map((o => {
      return (
        <MatchCard
          key={`match-card-${o.matchId}`}
          home={o.home}
          away={o.away}
          date={o.startDateUTC}
          teamSelection={forecasts[o.matchId] || o.result || ""}
          onClickHandler={(resultId) => onClickTeamHandler(o.matchId, resultId)}
          matchResult={o.matchResult}
        />
      );
    }));
  };

  const renderGroupsButtons = () => {
    return (
      <div className="grid grid-cols-4 md:grid-cols-8 gap-4 py-4">
        {Groups.map((group) => {
          return (
            <button
              key={group.id}
              type="button"
              onClick={() => {
                setExtrasVisibility(false);
                setSelectedGroup(group.id);
                setForecasts({});
                setSuccessMessage("");
                setErrorMessage("");
              }}
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {group.name}
            </button>
          )
        })}
      </div>
    );
  }

  const renderSecondPhaseButtons = () => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-5 gap-4 py-4">
        {SecondPhase.map((group) => {
          const isDisabled = false;
          return (
            <button
              key={group.id}
              type="button"
              onClick={() => {
                setExtrasVisibility(false);
                setSelectedGroup(group.id);
                setForecasts([]);
                setSuccessMessage("");
                setErrorMessage("");
              }}
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              disabled={isDisabled}
            >
              {group.name}
            </button>
          )
        })}
      </div>
    );
  }

  const renderExtrasButton = () => {
    return (
      <div className="grid grid-cols-1">
        <button
          key="btn-extras"
          type="button"
          onClick={() => {
            setExtrasVisibility(true);
            setSelectedGroup('EXTRAS');
            setForecasts({});
            setSuccessMessage("");
            setErrorMessage("");
          }}
          className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Extras
        </button>
      </div>
    );
  }

  const onClickSendHandler = () => {
    if (forecasts && !isExtrasVisible) {
      forecastsMutation.mutate(forecasts);
    } else if (isExtrasVisible) {
      extrasMutation.mutate(extraForecasts);
    }
  }

  return (
    <div className="w-full">
      <div className="grid place-items-center">
        {renderGroupsButtons()}
      </div>
      <div className="grid place-items-center">
        {renderSecondPhaseButtons()}
      </div>
      <div className="grid place-items-center">
        {renderExtrasButton()}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        {isExtrasVisible &&
          <ExtrasForecast
            selectedValues={extraForecasts}
            onChangeHandler={(type, value) => setExtraForecasts({ ...extraForecasts, [type]: value })}
          />}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        {renderVisibleGames()}
      </div>
      <div className="flex items-center justify-center my-4 py-4">
        <LoadingButton
          text="Enviar"
          onClickHandler={() => onClickSendHandler()}
          isLoading={forecastsMutation.isLoading || extrasMutation.isLoading}
        />
      </div>

      {successMessage !== "" &&
        <div id="alert-3" className="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
          <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
          <span className="sr-only">Info</span>
          <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
            Enviado!
          </div>
          <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-3" aria-label="Close">
            <span className="sr-only">Close</span>
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </button>
        </div>
      }

      {errorMessage !== "" &&
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {errorMessage}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
          </span>
        </div>
      }
    </div>
  );
}
