import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useAuthStore } from "../store";
import ExtrasCard from "./ExtrasCard";
import Spinner from "./Spinner";

interface Props {
  selectedValues: { champion: string; topScorer: string; };
  onChangeHandler: (type: "champion" | "topScorer", value: string) => void;
}

export default function ExtrasForecast(props: Props) {
  const worldCupStartDateUTC = "2022-11-20T16:00:00.000Z";

  return (
    <>
      <ExtrasCard
        type="champion"
        selectedValue={props.selectedValues.champion}
        date={worldCupStartDateUTC}
        onChangeHandler={props.onChangeHandler}
        teamSelection={1}
      />
      <ExtrasCard
        type="topScorer"
        date={worldCupStartDateUTC}
        selectedValue={props.selectedValues.topScorer}
        onChangeHandler={props.onChangeHandler}
        teamSelection={2}
      />
    </>
  );
}
