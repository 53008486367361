import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginFields } from "../constants/formFields";
import { CognitoService } from '../services';
import { ForecastService } from '../services/ForecastService';
import { MatchService } from '../services/MatchService';
import { StatsService } from '../services/StatsService';
import { useAuthStore } from '../store';
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import MatchCard from './MatchCard';
import Spinner from './Spinner';

const statsService = new StatsService();

const fields = loginFields;
let initialMatchState = [] as Array<any>;

const initialPositions = [] as any;

const getPositions = async (token: string) => {
  return await statsService.getPositions(token);
};

export default function Positions() {
  const navigate = useNavigate();
  const [matchState, setMatchState] = useState(initialMatchState);
  const [selectedGroup, setSelectedGroup] = useState('Group A');
  const [positions, setPositions] = useState(initialPositions);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const authData = useAuthStore((state: any) => state.auth);
  const token = authData?.signInUserSession?.idToken?.jwtToken;

  const { isFetching, error, refetch } = useQuery({
    queryKey: ['repoData'],
    queryFn: () => getPositions(token),
    enabled: !!token,
    retry: false,
    refetchOnMount: "always",
    staleTime: Infinity,
    onSuccess: (data) => {
      setPositions(data?.data);
    },
    onError(err) {
      setErrorMessage((err as Error).name);
      // console.log("ERRROR name", (err as Error).name);
      // console.log("ERRROR message", (err as Error).message);
    },
  });

  if (isFetching || !positions?.length) {
    return <Spinner />
  }

  return (
    <div className="w-full">
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">
                Posicion
              </th>
              <th scope="col" className="py-3 px-6">
                Usuario
              </th>
              <th scope="col" className="py-3 px-6">
                Total
              </th>
              <th scope="col" className="py-3 px-6">
                Grupos
              </th>
              <th scope="col" className="py-3 px-6">
                8vos
              </th>
              <th scope="col" className="py-3 px-6">
                4tos
              </th>
              <th scope="col" className="py-3 px-6">
                Semi
              </th>
              <th scope="col" className="py-3 px-6">
                3P
              </th>
              <th scope="col" className="py-3 px-6">
                Final
              </th>
              <th scope="col" className="py-3 px-6">
                Extras
              </th>
            </tr>
          </thead>
          <tbody>
            {positions.map((o) => (
              <tr key={o.position} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="p-4 w-4">
                  <div className="flex items-center">
                    {o.position}
                  </div>
                </td>
                <th scope="row" className="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap dark:text-white">
                  <img className="w-10 h-10 rounded-full" src={o.profilePicture} alt={o.username} />
                  <div className="pl-3">
                    <div className="text-base font-semibold">{o.username}</div>
                  </div>
                </th>
                <td className="py-4 px-6">
                  {o.totalPoints}
                </td>
                <td className="py-4 px-6">
                  {o?.pointsPerRound?.groups || '0'}
                </td>
                <td className="py-4 px-6">
                  {o?.pointsPerRound['Round of 16'] || '0'}
                </td>
                <td className="py-4 px-6">
                  0
                </td>
                <td className="py-4 px-6">
                  0
                </td>
                <td className="py-4 px-6">
                  0
                </td>
                <td className="py-4 px-6">
                  0
                </td>
                <td className="py-4 px-6">
                  0
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
}
