import { useState } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { votingFields } from "../constants/formFields"
import FormAction from "./FormAction";
import Input from "./Input";
import { useMutation, useQuery } from '@tanstack/react-query';
import { ProdeService } from '../services/ProdeService';
import { useLocation } from 'react-router-dom';

Amplify.configure({
  Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',
      // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      // mandatorySignIn: false,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: 'code', // 'code' | 'link' 
      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      /*
      cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
          domain: '.yourdomain.com',
      // OPTIONAL - Cookie path
          path: '/',
      // OPTIONAL - Cookie expiration in days
          expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
          // QsameSite: "strict" | "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
          secure: true
      },
      */
      // OPTIONAL - customized storage object
      // storage: MyStorage,
      
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      // authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      // clientMetadata: { myCustomKey: 'myCustomValue' },

       // OPTIONAL - Hosted UI configuration
      oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN_NAME,
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'http://localhost:3000/',
          redirectSignOut: 'http://localhost:3000/',
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
  }
});

// You can get the current config object
Auth.configure();

interface VotingFields {
  entryValue: number;
  resultType: string;
  secondPhaseGame: string;
  prizeDistribution: string;
  prizeFirst: number;
  prizeSecond: number;
  prizeThird: number;
  pointsGroups: number;
  pointsRound16: number;
  pointsQuarterFinal: number;
  pointsSemiFinal: number;
  points3rdPlace: number;
  pointsFinal: number;
  champion: boolean;
  championPoints: number
  runnerUp: boolean;
  runnerUpPoints: number;
  topScorer: boolean;
  topScorerPoints: number;
  mvp: boolean;
  mvpPoints: number;
  president: string;
}

/*
const cognitoService = new CognitoService();

const cognitoSignUp = async (signUpFields: SignUpFields) => {
  try {
      return await cognitoService.signUp(signUpFields);
  } catch (error) {
      console.log('error signing up:', error);
      return null;
  }
}
*/

const prodeService = new ProdeService();

const fields = votingFields;
let fieldsState = {} as VotingFields;

fields.forEach(field => fieldsState[field.id] = '');

const ResultTypes = {
  WithoutGoals: 'WITHOUT_GOALS',
  WithGoals: 'WITH_GOALS',
};

const SecondPhaseMatchEnd = {
  90: '90',
  120: '120',
  Penalties: 'PENALTIES',
};

const PrizeValue = {
  AllFirst: 'ALL_FIRST',
  EntryToSecond: 'ENTRY_TO_SECOND',
  Other: 'OTHER',
};

const PresidentValue = {
  Macri: 'MACRI',
  Cristina: 'CRISTINA',
};

const initialVotingState: VotingFields = {
  entryValue: 0,
  resultType: ResultTypes.WithoutGoals,
  secondPhaseGame: SecondPhaseMatchEnd[90],
  prizeDistribution: PrizeValue.AllFirst,
  prizeFirst: 0,
  prizeSecond: 0,
  prizeThird: 0,
  pointsGroups: 0,
  pointsRound16: 0,
  pointsQuarterFinal: 0,
  pointsSemiFinal: 0,
  points3rdPlace: 0,
  pointsFinal: 0,
  // extras: {
  champion: false,
  championPoints: 0,
  runnerUp: false,
  runnerUpPoints: 0,
  topScorer: false,
  topScorerPoints: 0,
  mvp: false,
  mvpPoints: 0,
  president: '',
  // },
};

const getPreferences = async (token: string) => {
  if (!token) {
    return null;
  }

  try {
      return await prodeService.getPreferences(token);
  } catch (error) {
      console.error('error getting preferences:', error);
      return null;
  }
};

export default function ProdeVoting() {
  const { state } = useLocation();
  const { token } = state; // Read values passed on state
  const [votingState, setVotingState] = useState(initialVotingState);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const vote = async (token: string, votingFields: VotingFields) => {
    if (votingFields.prizeDistribution === PrizeValue.Other) {
      const { prizeFirst, prizeSecond, prizeThird } = votingFields;
      
      const sum = prizeFirst + prizeSecond + prizeThird;
  
      if (sum !== 100) {
        throw new Error(`Los porcentajes tienen que sumar 100 y no ${sum}`);
      }
    }
    
    return await prodeService.sendPreferences(token, votingFields);
  }

  const { data, isLoading } = useQuery({
    queryKey: ['getPreferences', token],
    queryFn: () => getPreferences(token),
    enabled: !!token,
    staleTime: Infinity,
    onSuccess(data) {
        console.log("Success!", data);
        const preferences = data?.data;
        setVotingState({ ...votingState, ...preferences });
    },
  });

  const mutation: any = useMutation({
    mutationFn: (votingState: VotingFields) => {
      return vote(token, votingState);
    },
    onSuccess: () => {
      setSuccessMessage("Enviado!");
      setErrorMessage("");
    },
    onError(error) {
      console.error("Error", error);
      setErrorMessage((error as Error).message);
    },
  });

  if (isLoading) {
    // return <>Loading...</>;
  }

  if (data) {
    // setVotingState({ ...votingState, entryValue: preferences.data.entry_value });
    console.log("preferences", data);
  }

  const handleChange = (e) => {
    console.log("e", e);
    if (e.target.type === "radio") {
      console.log({ ...votingState, [e.target.name]: e.target.value });
      setVotingState({ ...votingState, [e.target.name]: e.target.value });
    } else if (e.target.type === "checkbox") {
      // debugger;
      console.log("ACA", { ...votingState, [e.target.name]: e.target.value });
      setVotingState({ ...votingState, [e.target.name]: !votingState[e.target.name] });
      console.log({ ...votingState, [e.target.name]: e.target.value });
    } else if (e.target.type === "number") {
      setVotingState({ ...votingState, [e.target.id]: e.target.valueAsNumber });
    } else {
      setVotingState({ ...votingState, [e.target.id]: e.target.value });
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!e) {
      setErrorMessage("Passwords no coinciden.");
    }

    mutation.mutate(votingState);

    /*
    console.log(signUpState)

    if (signUpState.password !== signUpState.confirmPassword) {
      setErrorMessage("Passwords no coinciden.");
    } else {
      setErrorMessage("");
      createAccount();
    }
    */
  }

  //handle Signup API Integration here
  /*
  const createAccount = async () => {
    const result = await cognitoSignUp(signUpState);

    console.log("Result", result);
  }
  */

  const fixedInputClass = "rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"

  return (
    <>
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <div className="">
          {
            fields.map(field =>
              <Input
                key={field.id}
                handleChange={handleChange}
                value={votingState[field.id]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                customClass=""
                srOnly={false}
              />

            )
          }

          <div className="my-5">
            <label>
              Tipo Resultado
            </label>
            <div className="grid grid-cols-2 gap-2">
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                  <input
                    checked={votingState.resultType === ResultTypes.WithoutGoals}
                    id="result_type_1"
                    type="radio"
                    value={ResultTypes.WithoutGoals}
                    name="resultType"
                    onChange={handleChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="bordered-radio-1" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Solo local, empate o visitante</label>
              </div>
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                  <input
                    checked={votingState.resultType === ResultTypes.WithGoals}
                    id="result_type_2"
                    type="radio"
                    value={ResultTypes.WithGoals}
                    name="resultType"
                    onChange={handleChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="bordered-radio-2" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Goles por equipo</label>
              </div>
            </div>
          </div>

          <div className="my-5">
            <label>
              Hasta que minuto los partidos de segunda fase
            </label>
            <div className="grid grid-cols-3 gap-3">
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                  <input
                    checked={votingState.secondPhaseGame === SecondPhaseMatchEnd[90]}
                    id="secondPhaseGame_1"
                    type="radio"
                    value={SecondPhaseMatchEnd[90]}
                    name="secondPhaseGame"
                    onChange={handleChange}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="secondPhaseGame_1" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">90</label>
              </div>
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                <input
                  checked={votingState.secondPhaseGame === SecondPhaseMatchEnd[120]}
                  id="secondPhaseGame_2"
                  type="radio"
                  value={SecondPhaseMatchEnd[120]}
                  name="secondPhaseGame"
                  onChange={handleChange}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                  <label htmlFor="secondPhaseGame_2" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">120</label>
              </div>
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                <input
                  checked={votingState.secondPhaseGame === SecondPhaseMatchEnd.Penalties}
                  id="secondPhaseGame_3"
                  type="radio"
                  value={SecondPhaseMatchEnd.Penalties}
                  name="secondPhaseGame"
                  onChange={handleChange}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                  <label htmlFor="secondPhaseGame_3" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Penales</label>
              </div>
            </div>
          </div>

          <div className="my-5">
            <label>
              Puntos por partido de fase (grupos, 8vos, 4tos, semi, 3er puesto, final)
            </label>
            <input
              onChange={handleChange}
              value={votingState.pointsGroups === 0 ? "" : votingState.pointsGroups}
              id="pointsGroups"
              name="pointsGroups"
              type={'number'}
              required={true}
              className={fixedInputClass}
              placeholder={'Grupos'}
              min="0"
            />
            <input
              onChange={handleChange}
              value={votingState.pointsRound16 === 0 ? "" : votingState.pointsRound16}
              id="pointsRound16"
              name="pointsRound16"
              type={'number'}
              required={true}
              className={fixedInputClass}
              placeholder={'8vos'}
              min="0"
            />
            <input
              onChange={handleChange}
              value={votingState.pointsQuarterFinal === 0 ? "" : votingState.pointsQuarterFinal}
              id="pointsQuarterFinal"
              name="pointsQuarterFinal"
              type={'number'}
              required={true}
              className={fixedInputClass}
              placeholder={'4tos'}
              min="0"
            />
            <input
              onChange={handleChange}
              value={votingState.pointsSemiFinal === 0 ? "" : votingState.pointsSemiFinal}
              id="pointsSemiFinal"
              name="pointsSemiFinal"
              type={'number'}
              required={true}
              className={fixedInputClass}
              placeholder={'Semifinal'}
              min="0"
            />
            <input
              onChange={handleChange}
              value={votingState.points3rdPlace === 0 ? "" : votingState.points3rdPlace}
              id="points3rdPlace"
              name="points3rdPlace"
              type={'number'}
              required={true}
              className={fixedInputClass}
              placeholder={'3er Puesto'}
              min="0"
            />
            <input
              onChange={handleChange}
              value={votingState.pointsFinal === 0 ? "" : votingState.pointsFinal}
              id="pointsFinal"
              name="pointsFinal"
              type={'number'}
              required={true}
              className={fixedInputClass}
              placeholder={'Final'}
              min="0"
            />
          </div>

          <div className="my-5">
            <label>
              Valor premio
            </label>
            <div className="grid grid-cols-3 gap-3">
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                  <input
                    checked={votingState.prizeDistribution === PrizeValue.AllFirst}
                    onChange={handleChange}
                    id="prize_distribution_1"
                    type="radio"
                    value={PrizeValue.AllFirst}
                    name="prizeDistribution"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="prize_distribution_1" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Todo al primero</label>
              </div>
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                  <input
                    checked={votingState.prizeDistribution === PrizeValue.EntryToSecond}
                    onChange={handleChange}
                    id="prize_distribution_1"
                    type="radio"
                    value={PrizeValue.EntryToSecond}
                    name="prizeDistribution"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="prize_distribution_2" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Al segundo la entrada y el resto al primero</label>
              </div>
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                  <input
                    checked={votingState.prizeDistribution === PrizeValue.Other}
                    onChange={handleChange}
                    id="prize_distribution_3"
                    type="radio"
                    value={PrizeValue.Other}
                    name="prizeDistribution"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="prize_distribution_3" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Otro (completar)</label>
              </div>
            </div>
          </div>

          {votingState.prizeDistribution === PrizeValue.Other &&
            <div className="my-5">
              <label>
                Porcentaje premio (1ro, 2do, 3ro)
              </label>
              <div className="grid grid-cols-3 gap-3">
                <div className="flex">
                  <input
                    type="number"
                    id="prizeFirst"
                    value={votingState.prizeFirst}
                    className="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="1ero"
                    onChange={handleChange}
                    min={0}
                    max={100 - votingState.prizeSecond - votingState.prizeThird}
                  />
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    %
                  </span>
                </div>
                <div className="flex">
                  <input
                    type="number"
                    id="prizeSecond"
                    value={votingState.prizeSecond}
                    className="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="2do"
                    onChange={handleChange}
                    min={0}
                    max={100 - votingState.prizeFirst - votingState.prizeThird}
                  />
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    %
                  </span>
                </div>
                <div className="flex">
                  <input
                    type="number"
                    id="prizeThird"
                    value={votingState.prizeThird}
                    className="rounded-none rounded-r-lg bg-gray-50 border text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="3ro"
                    onChange={handleChange}
                    min={0}
                    max={100 - votingState.prizeFirst - votingState.prizeSecond}
                  />
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border border-r-0 border-gray-300 dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                    %
                  </span>
                </div>
              </div>
            </div>
          }

          <div className="my-5">
            <label>
              Extras
            </label>

            <div className="grid grid-cols-2 gap-2">
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                  <input
                    checked={votingState.champion}
                    onChange={handleChange}
                    id="extras_champion"
                    type="checkbox"
                    name="champion"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="bordered-radio-1" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Campeon</label>
              </div>
              <div>
                {votingState.champion &&
                  <input
                    onChange={handleChange}
                    value={votingState.championPoints === 0 ? "" : votingState.championPoints}
                    id="championPoints"
                    name="championPoints"
                    type={'number'}
                    required={!!votingState.champion}
                    className={fixedInputClass}
                    placeholder={'Puntos'}
                    min="0"
                  />
                }
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                <input
                  checked={votingState.runnerUp}
                  onChange={handleChange}
                  id="extras_runner_up"
                  type="checkbox"
                  name="runnerUp"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="extras_runner_up" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Subcampeon</label>
              </div>
              <div>
                {votingState.runnerUp &&
                  <input
                    onChange={handleChange}
                    value={votingState.runnerUpPoints === 0 ? "" : votingState.runnerUpPoints}
                    id="runnerUpPoints"
                    name="runnerUpPoints"
                    type={'number'}
                    required={!!votingState.runnerUp}
                    className={fixedInputClass}
                    placeholder={'Puntos'}
                    min="0"
                  />
                }
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                <input
                  checked={votingState.topScorer}
                  onChange={handleChange}
                  id="extras_top_scorer"
                  type="checkbox"
                  name="topScorer"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="extras_mvp" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Goleador</label>
              </div>
              <div>
                {votingState.topScorer &&
                  <input
                    onChange={handleChange}
                    value={votingState.topScorerPoints === 0 ? "" : votingState.topScorerPoints}
                    id="topScorerPoints"
                    name="topScorerPoints"
                    type={'number'}
                    required={!!votingState.topScorer}
                    className={fixedInputClass}
                    placeholder={'Puntos'}
                    min="0"
                  />
                }
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                <input
                  checked={votingState.mvp}
                  onChange={handleChange}
                  id="extras_mvp"
                  type="checkbox"
                  name="mvp"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor="extras_mvp" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Mejor jugador</label>
              </div>
              <div>
                {votingState.mvp &&
                  <input
                    onChange={handleChange}
                    value={votingState.mvpPoints === 0 ? "" : votingState.mvpPoints}
                    id="mvpPoints"
                    name="mvpPoints"
                    type={'number'}
                    required={!!votingState.mvp}
                    className={fixedInputClass}
                    placeholder={'Puntos'}
                    min="0"
                  />
                }
              </div>
            </div>

            <div className="my-5">
            <label>
              Macri o Cristina?
            </label>
            <div className="grid grid-cols-3 gap-3">
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                  <input
                    checked={votingState.president === PresidentValue.Macri}
                    onChange={handleChange}
                    id="president_1"
                    type="radio"
                    value={PresidentValue.Macri}
                    name="president"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="prize_distribution_1" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Macri</label>
              </div>
              <div className="flex items-center pl-4 rounded border border-gray-200 dark:border-gray-700">
                  <input
                    checked={votingState.president === PresidentValue.Cristina}
                    onChange={handleChange}
                    id="president_2"
                    type="radio"
                    value={PresidentValue.Cristina}
                    name="president"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="prize_distribution_2" className="py-4 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Cristina</label>
              </div>
            </div>
          </div>

          </div>

          {errorMessage !== "" &&
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <strong className="font-bold">Error!</strong>
              <span className="block sm:inline"> {errorMessage}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
              </span>
            </div>
          }

          {successMessage !== "" &&
            <div id="alert-3" className="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
              <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
              <span className="sr-only">Info</span>
              <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
                Enviado!
              </div>
              <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-3" aria-label="Close">
                <span className="sr-only">Close</span>
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
            </div>
          }
          <FormAction handleSubmit={handleSubmit} text={data?.data || mutation.isSuccess ? "Actualizar" : "Enviar"} isLoading={mutation.isLoading} />
        </div>
      </form>
    </>
  )
}
