interface Props {
    text: string;
    size: number;
}

export default function Heading(props: Props) {
  if (props.size === 3) {
    return <h3 className="font-medium leading-tight text-4xl mt-0 my-2 py-2">{props.text}</h3>;
  } else if (props.size === 4) {
    return <h4 className="font-medium leading-tight text-2xl mt-0 my-1 py-1">{props.text}</h4>;
  }

  return <h1 className="font-medium leading-tight text-4xl mt-0 my-2 py-2">{props.text}</h1>;
}
