import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginFields } from "../constants/formFields";
import { Teams } from '../consts';
import { CognitoService } from '../services';
import { ForecastService } from '../services/ForecastService';
import { MatchService } from '../services/MatchService';
import { StatsService } from '../services/StatsService';
import { useAuthStore } from '../store';
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import MatchCard from './MatchCard';
import Spinner from './Spinner';

const Icons = {
  Right: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mx-auto text-green-600">
      <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
    </svg>
  ),
  Wrong: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mx-auto text-red-600">
      <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
    </svg>
  ),
  Unknown: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mx-auto text-yellow-600">
      <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
    </svg>
  ),
};

interface Forecast {
  username: string;
  result: number;
}

interface Props {
  id: number;
  homeId: number;
  awayId: number;
  result?: number;
  forecasts: Array<Forecast>;
}

const getIcon = (forecast, result) => {
  if (!result) {
    return Icons.Unknown;
  }

  if (forecast === result) {
    return Icons.Right;
  }

  return Icons.Wrong;
}

export default function ResultsTable(props: Props) {
  const evenClass = "bg-gray-100 border-b";
  const oddClass = "bg-white border-b";

  const home = Teams.find((o) => o.id === props.homeId)?.name || "";
  const away = Teams.find((o) => o.id === props.awayId)?.name || "";

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full border text-center">
              <thead className="border-b bg-gray-800">
                <tr>
                  <th scope="col" className="text-sm font-medium text-white px-6 py-4 border-r">
                    Usuario
                  </th>
                  <th scope="col" className="text-sm font-medium text-white px-6 py-4 border-r">
                    {home}
                  </th>
                  <th scope="col" className="text-sm font-medium text-white px-6 py-4 border-r">
                    Empate
                  </th>
                  <th scope="col" className="text-sm font-medium text-white px-6 py-4 border-r">
                    {away}
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.forecasts.map((o, index) => {
                  return (
                    <tr className={evenClass}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">{o.username}</td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap border-r">{o.result === props.homeId ? getIcon(o.result, props.result) : ""}</td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap border-r">{o.result === -1 ? getIcon(o.result, props.result) : ""}</td>
                      <td className="text-sm font-light px-6 py-4 whitespace-nowrap border-r">{o.result === props.awayId ? getIcon(o.result, props.result) : ""}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
