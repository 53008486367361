export const API_BASE_URL = process.env.REACT_APP_API_BASE_PATH;

export const Groups = [
  { id: 'Group A', name: 'Grupo A' },
  { id: 'Group B', name: 'Grupo B' },
  { id: 'Group C', name: 'Grupo C' },
  { id: 'Group D', name: 'Grupo D' },
  { id: 'Group E', name: 'Grupo E' },
  { id: 'Group F', name: 'Grupo F' },
  { id: 'Group G', name: 'Grupo G' },
  { id: 'Group H', name: 'Grupo H' },
];

export const SecondPhase = [
  { id: 'Round of 16', name: 'Octavos' },
  { id: 'Quarter Finals', name: 'Cuartos' },
  { id: 'Semi Finals', name: 'Semis' },
  { id: 'Third Place', name: '3er Puesto' },
  { id: 'Finals', name: 'Final' },
];

export const Teams = [
  {
    id: 19,
    name: "Alemania",
    ISOCode: "DE"
  },
  {
    id: 8,
    name: "Arabia Saudita",
    ISOCode: "SA"
  },
  {
    id: 7,
    name: "Argentina",
    ISOCode: "AR"
  },
  {
    id: 16,
    name: "Australia",
    ISOCode: "AU"
  },
  {
    id: 23,
    name: "Belgica",
    ISOCode: "BE"
  },
  {
    id: 31,
    name: "Brasil",
    ISOCode: "BR"
  },
  {
    id: 26,
    name: "Camerun",
    ISOCode: "CM"
  },
  {
    id: 24,
    name: "Canada",
    ISOCode: "CA"
  },
  {
    id: 22,
    name: "Costa Rica",
    ISOCode: "CR"
  },
  {
    id: 18,
    name: "Croacia",
    ISOCode: "HR"
  },
  {
    id: 11,
    name: "Dinamarca",
    ISOCode: "DK"
  },
  {
    id: 2,
    name: "Ecuador",
    ISOCode: "EC"
  },
  {
    id: 21,
    name: "España",
    ISOCode: "ES"
  },
  {
    id: 15,
    name: "Francia",
    ISOCode: "FR"
  },
  {
    id: 10,
    name: "Gales",
    ISOCode: "GB-WLS"
  },
  {
    id: 30,
    name: "Ghana",
    ISOCode: "GH"
  },
  {
    id: 6,
    name: "Holanda",
    ISOCode: "NL"
  },
  {
    id: 3,
    name: "Inglaterra",
    ISOCode: "GB-ENG"
  },
  {
    id: 4,
    name: "Iran",
    ISOCode: "IR"
  },
  {
    id: 20,
    name: "Japon",
    ISOCode: "JP"
  },
  {
    id: 28,
    name: "Korea",
    ISOCode: "KR"
  },
  {
    id: 13,
    name: "Mexico",
    ISOCode: "MX"
  },
  {
    id: 17,
    name: "Marruecos",
    ISOCode: "MA"
  },
  {
    id: 14,
    name: "Polonia",
    ISOCode: "PL"
  },
  {
    id: 29,
    name: "Portugal",
    ISOCode: "PT"
  },
  {
    id: 1,
    name: "Qatar",
    ISOCode: "QA"
  },
  {
    id: 5,
    name: "Senegal",
    ISOCode: "SN"
  },
  {
    id: 32,
    name: "Serbia",
    ISOCode: "RS"
  },
  {
    id: 25,
    name: "Suiza",
    ISOCode: "CH"
  },
  {
    id: 12,
    name: "Tunez",
    ISOCode: "TN"
  },
  {
    id: 27,
    name: "Uruguay",
    ISOCode: "UY"
  },
  {
    id: 9,
    name: "USA",
    ISOCode: "US"
  },
];
