interface FormField {
  labelText: string;
  labelFor: string;
  id: string;
  name: string;
  type: React.InputHTMLAttributes<HTMLInputElement>['type'];
  autoComplete: string;
  isRequired: boolean;
  placeholder: string;
}

const loginFields = [
  {
    labelText: "Email",
    labelFor: "emailAddress",
    id: "emailAddress",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email"
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "currentPassword",
    isRequired: true,
    placeholder: "Password"
  }
];

const forgotPasswordFields = [
  {
    labelText: "Email",
    labelFor: "emailAddress",
    id: "emailAddress",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email",
    step: 1,
  },
  {
    labelText: "Nuevo password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "password",
    isRequired: true,
    placeholder: "Nuevo password",
    step: 2,
  },
  {
    labelText: "Confirmar nuevo password",
    labelFor: "password",
    id: "confirmPassword",
    name: "confirmPassword",
    type: "password",
    autoComplete: "confirmPassword",
    isRequired: true,
    placeholder: "Confirmar nuevo password",
    step: 2,
  },
  {
    labelText: "Codigo",
    labelFor: "code",
    id: "code",
    name: "code",
    type: "text",
    autoComplete: "code",
    isRequired: true,
    placeholder: "Codigo",
    step: 2,
  },
];

const signupFields = [
  {
    labelText: "Usuario",
    labelFor: "username",
    id: "username",
    name: "username",
    type: "text",
    autoComplete: "usernameXXXX",
    isRequired: true,
    placeholder: "Usuario"
  },
  {
    labelText: "Email",
    labelFor: "emailAddress",
    id: "emailAddress",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email"
  },
  {
    labelText: "Nombre",
    labelFor: "firstName",
    id: "firstName",
    name: "firstName",
    type: "text",
    autoComplete: "firstName",
    isRequired: true,
    placeholder: "Nombre"
  },
  {
    labelText: "Apellido",
    labelFor: "lastName",
    id: "lastName",
    name: "lastName",
    type: "text",
    autoComplete: "lastName",
    isRequired: true,
    placeholder: "Apellido"
  },
  {
    labelText: "Password (al menos 6 caracteres, un numero y una letra minuscula)",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "currentPassword",
    isRequired: true,
    placeholder: "Password"
  },
  {
    labelText: "Confirmar Password",
    labelFor: "confirmPassword",
    id: "confirmPassword",
    name: "confirmPassword",
    type: "password",
    autoComplete: "confirmPassword",
    isRequired: true,
    placeholder: "Confirmar Password"
  }
];

const votingFields: Array<FormField> = [
  {
    labelText: "Valor entrada (ARS)",
    labelFor: "entryValue",
    id: "entryValue",
    name: "entryValue",
    type: "number",
    autoComplete: "entryValue",
    isRequired: true,
    placeholder: "$5000",
  },
];

export { loginFields, signupFields, votingFields, forgotPasswordFields };
