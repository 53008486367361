import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginFields } from "../constants/formFields";
import { Groups, SecondPhase } from '../consts';
import { CognitoService } from '../services';
import { ForecastService } from '../services/ForecastService';
import { MatchService } from '../services/MatchService';
import { StatsService } from '../services/StatsService';
import { useAuthStore } from '../store';
import FormAction from "./FormAction";
import FormExtra from "./FormExtra";
import Input from "./Input";
import MatchCard from './MatchCard';
import ResultsContainer from './ResultsContainer';
import ResultsExtrasContainer from './ResultsExtrasContainer';
import ResultsTable from './ResultsTable';
import Spinner from './Spinner';

const forecastService = new ForecastService();

const fields = loginFields;
let initialMatchState = [] as Array<any>;

const getAllMatchesAndForecasts = async (token: string) => {
  return await forecastService.getAllMatchesAndForecasts(token);
};

export default function Results() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState('Group A');
  const [matches, setMatches] = useState([] as any);
  const [extras, setExtras] = useState([] as any);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isExtrasVisible, setExtrasVisibility] = useState(selectedGroup === 'EXTRAS');

  const authData = useAuthStore((state: any) => state.auth);
  const token = authData?.signInUserSession?.idToken?.jwtToken;

  const { isFetching, error, refetch } = useQuery({
    queryKey: ['getAllMatchesAndForecasts'],
    queryFn: () => getAllMatchesAndForecasts(token),
    enabled: !!token,
    retry: false,
    refetchOnMount: "always",
    staleTime: Infinity,
    onSuccess: (data) => {
      setMatches(data?.data?.matches);
      setExtras(data?.data?.extraForecasts);
    },
    onError(err) {
      console.error("Error", err);
      setErrorMessage((err as Error).name);
      // console.log("ERRROR name", (err as Error).name);
      // console.log("ERRROR message", (err as Error).message);
    },
  });

  const renderGroupsButtons = () => {
    return (
      <div className="grid grid-cols-4 md:grid-cols-8 gap-4 py-4">
        {Groups.map((group) => {
          return (
            <button
              key={group.id}
              type="button"
              onClick={() => {
                setExtrasVisibility(false);
                setSelectedGroup(group.id);
              }}
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {group.name}
            </button>
          )
        })}
      </div>
    );
  };

  const renderSecondPhaseButtons = () => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-5 gap-4 py-4">
        {SecondPhase.map((group) => {
          const isDisabled = false;
          return (
            <button
              key={group.id}
              type="button"
              onClick={() => {
                setExtrasVisibility(false);
                setSelectedGroup(group.id);
              }}
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              disabled={isDisabled}
            >
              {group.name}
            </button>
          )
        })}
      </div>
    );
  };

  const renderExtrasButton = () => {
    return (
      <div className="grid grid-cols-1">
        <button
          key="btn-extras"
          type="button"
          onClick={() => {
            setExtrasVisibility(true);
            setSelectedGroup('EXTRAS');
          }}
          className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Extras
        </button>
      </div>
    );
  }

  if (isFetching) {
    return <Spinner />
  }

  const isExtras = selectedGroup === "EXTRAS";

  const groupMatches = (matches || []).filter((o) => o.round === selectedGroup) || [];

  return (
    <div className="w-full">
      <div className="grid place-items-center pb-5">
        {renderGroupsButtons()}
        {renderSecondPhaseButtons()}
        {renderExtrasButton()}
      </div>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <div id="accordion-collapse" data-accordion="collapse">
          {isExtras ? <ResultsExtrasContainer extras={extras} /> : <ResultsContainer matches={groupMatches} />}
        </div>
      </div>
    </div>
  );
}
