import { Auth } from "aws-amplify";

interface SignUpFields {
  username?: string;
  emailAddress: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
}

export const CognitoErrors = {
  UserNotFoundException: 'Email o password invalidos',
  NotAuthorizedException: 'Email o password invalidos',
};

export class CognitoService {
  public async signUp(signUpFields: SignUpFields) {
    const { username, password, emailAddress, firstName, lastName } = signUpFields;

    const { user } = await Auth.signUp({
      username: emailAddress as string, // username as string,
      password: password as string,
      attributes: {
        email: emailAddress,          // optional
        given_name: firstName,
        family_name: lastName,
        preferred_username: username,
        // phone_number,   // optional - E.164 number convention
        // other custom attributes 
        // 'custom:entryValue': "15",
      },
      autoSignIn: { // optional - enables auto sign in after user is confirmed
        enabled: false,
      }
    });

    return user;
  }

  public async signIn(username: string, password: string) {
    const user = await Auth.signIn(username, password);

    return user;
  }

  public async signOut() {
    await Auth.signOut();
    return true;
  }

  public async forgotPassword(username: string) {
    await Auth.forgotPassword(username);
    return true;
  }

  public async forgotPasswordSubmit(username: string, code: string, newPassword: string) {
    await Auth.forgotPasswordSubmit(username, code, newPassword)
    return true;
  }
}
