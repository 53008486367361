import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Teams } from "../consts";
import TeamButton from "./TeamButton";
import TeamSelect from "./TeamSelect";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  id: number;
  date: string;
  home: string;
  away: string;
  isAdmin?: boolean;
  onClickHandler: (matchId: number, teamId: string, homeOrAway: "home" | "away") => undefined;
}

export default function FutureMatchCard(props: Props) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Argentina/Buenos_Aires";

  const dateWithTimezone = (dayjs(props.date).tz(timeZone)).format('DD-MM-YYYY HH:mm');

  return (
    <div className={`bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-6 dark:bg-gray-800 dark:border-gray-700 mx-2 my-4`}>
      <div className="my-3">
        <div id="tab" className="w-full flex py-1 justify-center">
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
            </svg>
            <span className="block">{dateWithTimezone}</span>
          </p>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-3">
        <TeamSelect
          selectedValue={props.home}
          onChangeHandler={(teamId) => props.onClickHandler(props.id, teamId, "home")}
        />

        <div className="flex justify-center">vs</div>

        <TeamSelect
          selectedValue={props.away}
          onChangeHandler={(teamId) => props.onClickHandler(props.id, teamId, "away")}
        />
      </div>
    </div>
  );
}
