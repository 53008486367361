import axios from "axios";
import { API_BASE_URL } from "../consts";

export class ForecastService {
  public async getMatchesAndForecasts(token: string, username: string) {
    try {
      const url = `${API_BASE_URL}/forecasts/${username}`;

      return axios.get(url, {
        headers: {
          'authorization': token,
        },
      });
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }

  public async getAllMatchesAndForecasts(token: string) {
    try {
      const url = `${API_BASE_URL}/forecasts`;

      return axios.get(url, {
        headers: {
          'authorization': token,
        },
      });
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }

  public async setForecasts(token: string, username: string, forecasts: any) {
    try {
      const url = `${API_BASE_URL}/forecasts/${username}`;

      const data = [ ...forecasts ];

      return axios.post(
        url,
        data,
        {
          headers: {
            'authorization': token,
          },
        },
      );
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }

  public async setExtraForecasts(token: string, username: string, forecasts: any) {
    try {
      const url = `${API_BASE_URL}/extra-forecasts/${username}`;

      const data = [ ...forecasts ];

      return axios.post(
        url,
        data,
        {
          headers: {
            'authorization': token,
          },
        },
      );
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }

  public async getResults(round: string) {
    return Promise.resolve([
      {
        home: 'Argentina',
        away: 'Mexico',
        forecasts: [
          { username: 'Lorenzo', result: 'Argentina' },
          { username: 'Lorenzo1', result: 'Mexico' },
          { username: 'Lorenzo2', result: 'Empate' },
        ],
      },
      {
        home: 'Mexico',
        away: 'Polonia',
        forecasts: [
          { username: 'Lorenzo', result: 'Argentina' },
          { username: 'Lorenzo1', result: 'Mexico' },
          { username: 'Lorenzo2', result: 'Empate' },
        ],
      },
    ]);
  }
}
