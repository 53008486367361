import Button from "./Button";

type Type = 'submit' | 'reset' | 'button' | undefined;

export default function FormAction({
  handleSubmit,
  type = 'button' as Type,
  action = 'submit' as Type,
  isLoading = false,
  isDisabled = false,
  text
}) {
  return (
    <>
      {
        type === 'button' ?
          <Button
            handleSubmit={handleSubmit}
            action={action}
            text={text}
            isLoading={isLoading}
            isDisabled={isDisabled}
          />
          :
          <></>
      }
    </>
  )
}
