import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Teams } from "../consts";

dayjs.extend(utc);
dayjs.extend(timezone);

interface Team {
  id: number;
  name: string;
  ISOCode: string;
}

type Type = "champion" | "topScorer";

interface Props {
  type: Type
  date: string;
  teamSelection: Team["id"];
  onChangeHandler: (type: Type, value: string) => void;
  selectedValue: string;
  isAdmin?: boolean;
}

const hasMatchStarted = (startDateUTC: string) => dayjs().isAfter(dayjs(startDateUTC));

export default function ExtrasCard(props: Props) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Argentina/Buenos_Aires";

  const dateWithTimezone = (dayjs(props.date).tz(timeZone)).format('DD-MM-YYYY HH:mm');
  const fixedInputClass = "rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm"

  const isExtraDisabled = props.isAdmin ? false : hasMatchStarted(props.date);

  let input: JSX.Element;

  if (props.type === "champion") {
    input = (
      <select
        defaultValue={props.selectedValue}
        value={props.selectedValue}
        id="countries"
        onChange={(e) => props.onChangeHandler("champion", e.target.value)}
        disabled={isExtraDisabled}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option value="-1">Campeon</option>
        {Teams.map((o: Team) => {
          return (
            <option key={`option-team-${o.id}`} value={o.ISOCode}>{o.name}</option>
          )
        })}
      </select>
    );
  } else {
    input = (
      <input
        onChange={(e) => props.onChangeHandler("topScorer", e.target.value)}
        value={props.selectedValue}
        id="topScorer"
        name="topScorer"
        type="text"
        // required={isRequired}
        className={fixedInputClass}
        placeholder="Goleador"
        autoComplete="false"
        disabled={isExtraDisabled}
      />
    );
  }

  return (
    <div className="bg-white rounded-lg border border-gray-200 shadow-md sm:p-6 md:p-6 dark:bg-gray-800 dark:border-gray-700 mx-2 my-4">
      <div className="my-3">
        <div id="tab" className="w-full flex py-1 justify-center">
          <p>
            { props.type === "champion" ? "Campeon (10 puntos)" : "Goleador (5 puntos)" }
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1">
        <label htmlFor={props.type}>
          &nbsp;
        </label>
        {input}
      </div>
      <div className="my-3">
        <div id="tab" className="w-full flex py-1 justify-center">
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
            </svg>
            <span className="block">{dateWithTimezone}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
