import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow'
import { forgotPasswordFields } from "../constants/formFields";
import { CognitoService } from '../services';
import { useAuthStore } from '../store';
import FormAction from "./FormAction";
import Input from "./Input";

const cognitoService = new CognitoService();

const fields = forgotPasswordFields
let fieldsState = {} as LoginFields;
fields.forEach(field => fieldsState[field.id] = '');

interface LoginFields {
  emailAddress: string;
  password: string;
  code: string;
}

const cognitoForgotPassword = async (username: string) => {
  if (!username) {
    return null;
  }

  return await cognitoService.forgotPassword(username);
};

const cognitoForgotPasswordSubmit = async (username: string, code: string, password: string) => {
  if (!username || !code || !password) {
    return null;
  }

  return await cognitoService.forgotPasswordSubmit(username, code, password);
};

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [loginState, setLoginState] = useState(fieldsState);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [step, setStep] = useState(1);
  /// const [authData, setAuthData] = useState(undefined);
  // const setAuthDataStore = useAuthStore((state: any) => state.setAuth({ ...state }));

  const [auth, setAuthData] = useAuthStore(
    (state: any) => [state.auth, state.setAuthData],
    shallow,
  );

  const forgotPasswordMutation: any = useMutation({
    mutationFn: (emailAddress: string) => {
      return cognitoForgotPassword(emailAddress);
    },
    onSuccess: () => {
      setStep(2);
      setSuccessMessage("Te enviamos un mail con un codigo para resetear tu password.");
    },
    onError(error) {
      setErrorMessage((error as Error).name);
    },
    retry: false,
  });

  const forgotPasswordSubmitMutation: any = useMutation({
    mutationFn: (loginState: LoginFields) => {
      return cognitoForgotPasswordSubmit(loginState.emailAddress, loginState.code, loginState.password);
    },
    onSuccess: () => {
      setStep(3);
      setSuccessMessage("Password actualizado!");
    },
    onError(error) {
      setErrorMessage((error as Error).name);
    },
    retry: false,
  });

  /*
  const { isFetching, error, refetch } = useQuery({
    queryKey: ['forgotPassword', loginState],
    queryFn: () => cognitoForgotPassword(loginState.emailAddress),
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      console.log("Data", data);
      setStep(2);
    },
    onError(err) {
      setErrorMessage((err as Error).name);
    },
  });
  */
  // const { data, isLoading, error, refetch} = useQuery(['getkurs', loginState], () => cognitoSignIn(loginState));

  /*
  if (loginState.token) {
    navigate('/voting', { state: { token: loginState.token } });
    // return <Navigate to="/voting" />
  }
  */

  const handleChange = (e) => {
    setLoginState({ ...loginState, [e.target.id]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step === 1) {
      return forgotPasswordMutation.mutate(loginState.emailAddress);
    } else if (step === 2) {
      forgotPasswordSubmitMutation.mutate(loginState);
    } else {
      throw new Error("Invalid step");
    }
    // authenticateUser();
  }

  // if (isLoading) return <>'Loading...'</>;

  // if (error) return <>An error has occurred: ${(error as any).message}</>;

  /*
  if (data) {
    console.log("Setting token");
    setLoginState({ ...loginState, token: data.signInUserSession.idToken.jwtToken });
  }
  */

  /*
  if (loginState.token) {
    console.log("Token", loginState.token);
    refetchPreferences();
  }
  */

  /*
  if (data) {
    return (
      <>
        TOKEN: {data.signInUserSession.idToken.jwtToken}
      </>
    );
  }
  */

  /*
  if (preferences) {
    return (
      <>
        TOKEN: {JSON.stringify(preferences)}
      </>
    ); 
  }
  */

  const buttonText = step === 1 ? "Solicitar codigo" : "Resetear password";

  return (
    <div className="flex flex-col justify-center items-center">
      <form className="mt-8 space-y-6 w-full sm:w-1/2" onSubmit={handleSubmit}>
        <div className="">
          {
            fields.filter((o) => o.step <= step).map(field =>
              <Input
                key={field.id}
                handleChange={handleChange}
                value={loginState[field.id]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                placeholder={field.placeholder}
                customClass=""
                srOnly={false}
                isDisabled={field.id === "emailAddress" && step === 2}
              />

            )
          }
        </div>

        <FormAction handleSubmit={handleSubmit} text={buttonText} isLoading={forgotPasswordMutation.isLoading} isDisabled={step === 3} />

        {successMessage !== "" &&
          <div id="alert-3" className="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
            <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
            <span className="sr-only">Info</span>
            <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
              {successMessage}
            </div>
            <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-3" aria-label="Close">
              <span className="sr-only">Close</span>
              <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </div>
        }

        {errorMessage !== "" &&
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error!</strong>
            <span className="block sm:inline"> {errorMessage}</span>
            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
            </span>
          </div>
        }
      </form>
    </div>
  )
}
