import { useState } from 'react';
import Heading from './Heading';

const Sections = {
  Goal: 1,
  Points: 2,
  Prizes: 3,
  Tie: 4,
};

export default function Rules() {
  const [isVisible, setIsVisible] = useState(1);

  return (
    <div className="w-full">
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <div id="accordion-collapse" data-accordion="collapse">
          <h2 id="accordion-collapse-heading-1">
            <button
              type="button"
              onClick={() => setIsVisible(isVisible === Sections.Goal ? -1 : Sections.Goal)}
              className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
              <div id="tab" className="w-full flex py-1">
                OBJETIVO
              </div>
              <svg data-accordion-icon="" className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-1" className={isVisible === Sections.Goal ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-1">
            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
              <p className="mb-2 text-gray-900 dark:text-gray-400">
                El juego consiste en adivinar el ganador de cada partido del mundial.
              </p>
              <p className="mb-2 text-gray-900 dark:text-gray-400">
                Cada acierto suma puntos dependiendo de la fase a la que corresponde el partido (ver Puntajes).
                <br />
                Tambien suma puntos acertar campeon y goleador.
              </p>
              <p>
                Gana el que suma mas puntos (ver Premios).
              </p>
              <p className="mb-2 text-gray-900 dark:text-gray-400">
                El resultado de cada partido se puede cambiar hasta el comienzo del partido. No hace falta mandar todos los resultados juntos.
              </p>
              <p className="mb-2 text-gray-900 dark:text-gray-400">
                Para los partidos de octavos de final en adelante se considera el resultado hasta el minuto 90, sin importar lo que pase si hay alargue o penales.
              </p>
            </div>
          </div>
          <h2 id="accordion-collapse-heading-2">
            <button
              type="button"
              onClick={() => setIsVisible(isVisible === Sections.Points ? -1 : Sections.Points)}
              className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
              <span>PUNTAJES</span>
              <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-2" className={isVisible === Sections.Points ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-2">
            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
              <div className="grid grid-cols-2">
                <div>
                  <Heading text="Partidos" size={4} />
                  <ul className="list-disc pl-3">
                    <li><u>Fase de grupos:</u> 1 punto</li>
                    <li><u>Octavos:</u> 2 puntos</li>
                    <li><u>Cuartos de final:</u> 3 puntos</li>
                    <li><u>Semi final:</u> 4 puntos</li>
                    <li><u>Tercer puesto:</u> 4 puntos</li>
                    <li><u>Final:</u> 5 puntos</li>
                  </ul>
                </div>

                <div>
                  <Heading text="Extras" size={4} />
                  <ul className="list-disc pl-3">
                    <li><u>Campeon:</u> 10 puntos</li>
                    <li><u>Goleador:</u> 5 puntos</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <h2 id="accordion-collapse-heading-3">
            <button
              type="button"
              onClick={() => setIsVisible(isVisible === Sections.Prizes ? -1 : Sections.Prizes)}
              className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
              <span>PREMIOS</span>
              <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-3" className={isVisible === Sections.Prizes ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-3">
            <div className="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700">
              <ul className="list-disc pl-3">
                <li><u>Ganador:</u> todo lo recaudado menos el valor de una entrada</li>
                <li><u>Segundo puesto:</u> el valor de la entrada</li>
              </ul>
            </div>
          </div>
          <h2 id="accordion-collapse-heading-4">
            <button
              type="button"
              onClick={() => setIsVisible(isVisible === Sections.Tie ? -1 : Sections.Tie)}
              className="flex items-center justify-between w-full p-5 font-medium text-left border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
              <span>DESEMPATES</span>
              <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
          </h2>
          <div id="accordion-collapse-body-4" className={isVisible === Sections.Tie ? "" : "hidden"} aria-labelledby="accordion-collapse-heading-3">
            <div className="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700">
              <p className="mb-2 text-gray-900 dark:text-gray-400">En caso de empate en puntos</p>
              <ul className="list-decimal pl-3">
                <li>El que acierte mayor cantidad de partidos</li>
                <li>El que acierte el campeon</li>
                <li>El que acierte goleador</li>
              </ul>
              <br />
              <p className="mb-2 text-gray-900 dark:text-gray-400">En caso de empatar en todo, se divide el premio entre el numero de ganadores.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  /*
  return (
    <div className="w-full box-border py-4 px-8 border-4 m-5">
      <Heading text="Objetivo" size={3} />
      <p>
        El juego consiste en adivinar el ganador de cada partido del mundial.
      </p>
      <p>
        Para los partidos de octavos de final en adelante se considera el resultado hasta el minuto 90, sin importar lo que pase si hay alargue o penales.
      </p>
      <p>
        El resultado de cada partido se puede cambiar hasta el momento en el que empieza el partido. No hace falta mandar todos los resultados juntos.
      </p>
      <p>
        Cada acierto suma puntos dependiendo de la fase a la que corresponde el partido (ver Puntaje).
      </p>
      <p>
        Gana el que suma mas puntos.
      </p>

      <Heading text="Puntaje" size={3} />
      <Heading text="Partidos" size={4} />
      <ul className="list-disc">
        <li>Fase de grupos: 1 punto</li>
        <li>Octavos: 2 puntos</li>
        <li>Cuartos de final: 3 puntos</li>
        <li>Semi final: 4 puntos</li>
        <li>Tercer puesto: 4 puntos</li>
        <li>Final: 5 puntos</li>
      </ul>

      <Heading text="Extras" size={4} />
      <ul className="list-disc">
        <li>Campeon: 10 puntos</li>
        <li>Goleador: 10 puntos</li>
      </ul>

      <Heading text="Premios" size={3} />
      <ul className="list-decimal">
        <li>Ganador: todo lo recaudado menos el valor de una entrada</li>
        <li>Segundo puesto: el valor de la entrada</li>
      </ul>

      <Heading text="Criterios de desempate" size={3} />
      <p>En caso de empate en puntos</p>
      <ul className="list-decimal">
        <li>El que acierte mayor cantidad de partidos</li>
        <li>El que acierte el campeon</li>
        <li>El que acierte goleador</li>
      </ul>

      <p>En caso de empatar en todo, se divide el premio en 2.</p>

    </div>
  );
  */
}
