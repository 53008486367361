import axios from "axios";
import { API_BASE_URL } from "../consts";

interface VotingFields {
  entryValue: number;
  resultType: string;
  secondPhaseGame: string;
  prizeDistribution: string;
  prizeFirst: number;
  prizeSecond: number;
  prizeThird: number;
  pointsGroups: number;
  pointsRound16: number;
  pointsQuarterFinal: number;
  pointsSemiFinal: number;
  points3rdPlace: number;
  pointsFinal: number;
  champion: boolean;
  championPoints: number
  runnerUp: boolean;
  runnerUpPoints: number;
  topScorer: boolean;
  topScorerPoints: number;
  mvp: boolean;
  mvpPoints: number;
}

export class ProdeService {
  public async sendPreferences(token: string, votingFields: VotingFields) {
    try {
      const username = "user";
      const url = `${API_BASE_URL}/preferences/${username}`;

      const data = { ...votingFields };

      return axios.post(
        url,
        data,
        {
          headers: {
            'authorization': token,
          },
        },
      );
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }

  public async getPreferences(token: string) {
    try {
      const username = "user";
      const url = `${API_BASE_URL}/preferences/${username}`;

      return axios.get(url, {
        headers: {
          'authorization': token,
        },
      });
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }

  public async getAllTeams(token: string) {
    try {
      const url = `${API_BASE_URL}/teams`;

      return axios.get(url, {
        headers: {
          'authorization': token,
        },
      });
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }
}
