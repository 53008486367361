import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { MatchService } from '../services/MatchService';
import MatchCard from './MatchCard';
import LoadingButton from './LoadingButton';
import { useAuthStore } from '../store';
import Spinner from './Spinner';
import FutureMatchCard from './FutureMatchCard';
import { Teams } from '../consts';

const matchService = new MatchService();

let initialMatchState = [] as Array<any>;

const initialResults = {} as Record<number, number>;

const getAllMatches = async (token: string) => {
  return await matchService.getAllMatches(token);
};

const sendMatchesResult = async (token: string, results: any) => {
  const matchIds = Object.keys(results);
  const requestBody = [] as Array<any>;

  matchIds.forEach((matchId) => {
    requestBody.push({
      matchId,
      result: results[matchId] || null,
    });
  });

  return await matchService.setMatchesResult(token, requestBody);
};

const loadNewMatches = async (token: string, newMatches: any) => {
  const matchesWithId = newMatches.map((o) => {
    if (!o.home || !o.away) {
      return undefined;
    }

    const home = Teams.find((t) => t.ISOCode === o.home);
    const away = Teams.find((t) => t.ISOCode === o.away);

    if (!home || !away) {
      return undefined;
    }

    return {
      ...o,
      homeId: home.id,
      awayId: away.id,
    };
  }).filter((o) => o !== undefined);

  return await matchService.setNewMatches(token, matchesWithId);
};

const initialSelectedGroup = 'Group A';

const teamIsDefined = (team: { ISOCode: string }) => team?.ISOCode && team?.ISOCode !== 'tbd';

const matchIsMissingOneTeam = (match: any) => (teamIsDefined(match.home) && !teamIsDefined(match.away)) || (teamIsDefined(match.away) && !teamIsDefined(match.home));

interface NewMatch {
  matchId: number;
  home: string;
  away: string;
}

export default function AdminMatches() {
  const [matchState, setMatchState] = useState(initialMatchState);
  const [selectedGroup, setSelectedGroup] = useState(initialSelectedGroup);
  const [results, setResults] = useState(initialResults);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [newMatches, setNewMatches] = useState([] as Array<NewMatch>);
  const [isNewGame, setIsNewGame] = useState(false);

  const authData = useAuthStore((state: any) => state.auth);
  const token = authData?.signInUserSession?.idToken?.jwtToken;
  const username = authData?.attributes?.preferred_username;
  const isAdmin = true;

  const { isFetching } = useQuery({
    queryKey: ['getAllMatches'],
    queryFn: () => getAllMatches(token),
    enabled: !!token, // true,
    retry: false,
    refetchOnMount: "always",
    staleTime: Infinity,
    onSuccess: (data) => {
      const matches = data?.data;
      setMatchState(matches);

      // const newMatches = [];

      /*
      matches.forEach((match) => {
        if (matchIsMissingOneTeam(match)) {
          console.log("match is missing one team", match);
        }
      });
      */
    },
    onError(err) {
      setErrorMessage((err as Error).name);
    },
  });

  const setResultMutation: any = useMutation({
    mutationFn: (forecasts: any) => {
      return sendMatchesResult(token, forecasts);
    },
    onSuccess: () => {
      const matchIds = Object.keys(results);
      matchIds.forEach((matchId) => {
        const matchIndex = matchState.findIndex((o) => o.matchId === parseInt(matchId, 10));

        if (matchIndex >= 0) {
          matchState[matchIndex].result = results[matchId];
        }
      });
      setMatchState(matchState);
      setSuccessMessage("Enviado!");
      setErrorMessage("");
    },
    onError(error) {
      console.error("Error", error);
      setErrorMessage((error as Error).message);
    },
  });

  const loadNewGameMutation: any = useMutation({
    mutationFn: (newMatches: any) => {
      return loadNewMatches(token, newMatches);
    },
    onSuccess: () => {
      const matchIds = Object.keys(results);
      matchIds.forEach((matchId) => {
        const matchIndex = matchState.findIndex((o) => o.matchId === parseInt(matchId, 10));

        if (matchIndex >= 0) {
          matchState[matchIndex].result = results[matchId];
        }
      });
      setMatchState(matchState);
      setSuccessMessage("Enviado!");
      setErrorMessage("");
    },
    onError(error) {
      console.error("Error", error);
      setErrorMessage((error as Error).message);
    },
  });

  if (!errorMessage && (isFetching || !matchState?.length)) {
    return <Spinner />;
  }

  const Groups = [
    { id: 'Group A', name: 'Grupo A' },
    { id: 'Group B', name: 'Grupo B' },
    { id: 'Group C', name: 'Grupo C' },
    { id: 'Group D', name: 'Grupo D' },
    { id: 'Group E', name: 'Grupo E' },
    { id: 'Group F', name: 'Grupo F' },
    { id: 'Group G', name: 'Grupo G' },
    { id: 'Group H', name: 'Grupo H' },
  ];

  const SecondPhase = [
    { id: 'Round of 16', name: 'Octavos' },
    { id: 'Quarter Finals', name: 'Cuartos' },
    { id: 'Semi Finals', name: 'Semis' },
    { id: 'Third Place', name: '3er Puesto' },
    { id: 'Finals', name: 'Final' },
  ];

  const onClickTeamHandler = (matchId: number, resultId: number) => {
    setResults({ ...results, [matchId]: resultId });
    return undefined;
  };

  const onClickFutureMatchHandler = (matchId: number, teamId: string, type: "home" | "away") => {
    const newGame = { matchId, home: "", away: "", [type]: teamId };
    const matchIndex = newMatches.findIndex((o) => o.matchId === matchId);

    if (matchIndex >= 0) {
      const match = { ...newMatches[matchIndex], [type]: teamId };
      const newArray = newMatches.filter((o) => o.matchId !== matchId);
      setNewMatches([...newArray, match ]);
    } else {
      setNewMatches([...newMatches, newGame ]);
    }
    return undefined;
  };

  const renderVisibleGames = () => {
    return (matchState || []).filter((o) => o.round === selectedGroup).map((o => {
      if (teamIsDefined(o.home) && teamIsDefined(o.away)) {
        return (
          <MatchCard
            key={`match-card-${o.matchId}`}
            home={o.home}
            away={o.away}
            date={o.startDateUTC}
            teamSelection={results[o.matchId] || o.result || ""}
            onClickHandler={(resultId) => onClickTeamHandler(o.matchId, resultId)}
            isAdmin={isAdmin}
          />
        );
      } else {
        const newMatch = newMatches.find((m) => m.matchId === o.matchId);
        return (
          <FutureMatchCard
            key={`future-match-card-${o.matchId}`}
            id={o.matchId}
            home={newMatch?.home || "-1"}
            away={newMatch?.away || "-1"}
            date={o.startDateUTC}
            onClickHandler={(matchId, teamId, type) => onClickFutureMatchHandler(matchId, teamId, type)}
          />
        )
      }
    }));
  };

  const renderGroupsButtons = () => {
    return (
      <div className="grid grid-cols-4 md:grid-cols-8 gap-4 py-4">
        {Groups.map((group) => {
          return (
            <button
              key={group.id}
              type="button"
              onClick={() => {
                setSelectedGroup(group.id);
                setResults({});
                setNewMatches([]);
                setSuccessMessage("");
                setErrorMessage("");
                setIsNewGame(false);
              }}
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {group.name}
            </button>
          )
        })}
      </div>
    );
  }

  const renderSecondPhaseButtons = () => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-5 gap-4 py-4">
        {SecondPhase.map((group) => {
          return (
            <button
              key={group.id}
              type="button"
              onClick={() => {
                setSelectedGroup(group.id);
                setResults({});
                setNewMatches([]);
                setSuccessMessage("");
                setErrorMessage("");
                setIsNewGame(true);
              }}
              className="bg-blue-500 hover:bg-blue-700 focus:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {group.name}
            </button>
          )
        })}
      </div>
    );
  }

  const onClickSendHandler = () => {
    console.log("results", results);
    console.log("newMatches", newMatches);

    if (isNewGame) {
      loadNewGameMutation.mutate(newMatches);
    } else {
      setResultMutation.mutate(results);
    }

    if (results) {
      console.log("Send results");
      setResultMutation.mutate(results);
    }
  }

  return (
    <div className="w-full">
      <div className="grid place-items-center">
        {renderGroupsButtons()}
      </div>
      <div className="grid place-items-center">
        {renderSecondPhaseButtons()}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        {renderVisibleGames()}
      </div>
      <div className="flex items-center justify-center my-4 py-4">
        <LoadingButton
          text="Enviar"
          onClickHandler={() => onClickSendHandler()}
          isLoading={setResultMutation.isLoading}
        />
      </div>

      {successMessage !== "" &&
        <div id="alert-3" className="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
          <svg aria-hidden="true" className="flex-shrink-0 w-5 h-5 text-green-700 dark:text-green-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
          <span className="sr-only">Info</span>
          <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
            Enviado!
          </div>
          <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-3" aria-label="Close">
            <span className="sr-only">Close</span>
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </button>
        </div>
      }

      {errorMessage !== "" &&
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {errorMessage}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
          </span>
        </div>
      }
    </div>
  );
}
