import axios from "axios";
import { API_BASE_URL } from "../consts";

export class MatchService {
  public async getAllMatches(token: string) {
    try {
      const url = `${API_BASE_URL}/match`;

      return axios.get(url, {
        headers: {
          'authorization': token,
        },
      });
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }

  public async setMatchesResult(token: string, matches: Array<{ matchId: number; result: number }>) {
    try {
      const url = `${API_BASE_URL}/match-results`;

      const data = [ ...matches ];

      return axios.patch(
        url,
        data,
        {
          headers: {
            'authorization': token,
          },
        },
      );
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }

  public async setNewMatches(token: string, matches: Array<{ matchId: number; homeId: number; home: string; awayId: number; away: string }>) {
    if (!matches?.length) {
      console.log("Nothing to send");
      return true;
    }

    try {
      const url = `${API_BASE_URL}/match`;

      const data = [ ...matches ];

      return axios.patch(
        url,
        data,
        {
          headers: {
            'authorization': token,
          },
        },
      );
    } catch (error) {
      console.error("Error", error);
      return null;
    }
  }
}
