interface Props {
  teamId: number;
  name: string;
  isoCode?: string;
  isActive: boolean;
  isDisabled?: boolean;
  onClickHandler: (resultId: number) => undefined;
}

export default function TeamButton(props: Props) {
  const hoverClass = !props.isDisabled ? "hover:bg-blue-500 hover:text-white hover:border-transparent" : "";
  const baseClass = `text-xs sm:text-sm font-semibold ${hoverClass} py-2 px-4 border border-blue-500 rounded`;
  const isActiveClass = props.isActive ? "text-white bg-blue-500 border-transparent" : "text-blue-700 bg-transparent";

  const flagIcon = props.isoCode ? <><span className={`fi fi-${props.isoCode}`}></span>&nbsp;</> : null;
  const cursorClass = props.isDisabled ? "cursor-not-allowed" : "";

  return (
    <button
      type="button"
      className={`${baseClass} ${isActiveClass} ${cursorClass}`}
      onClick={() => props.onClickHandler(props.teamId)}
      disabled={props.isDisabled}
    >
      {flagIcon}
      {props.name}
    </button>
  );
}
