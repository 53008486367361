import { Fragment, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SignUpPage from './pages/SignUpPage';
import LoginPage from './pages/LoginPage';
import VotingPage from './pages/VotingPage';
import MatchesPage from './pages/MatchesPage';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Positions from './components/Positions';
import Results from './components/Results';
import { useAuthStore } from './store';
import Rules from './components/Rules';
import AdminMatchesPage from './pages/admin/MatchesPage';
import ForgotPassword from './components/ForgotPassword';
import ForgotPasswordPage from './pages/ForgotPasswordPage';

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://media.istockphoto.com/id/587805156/vector/profile-picture-vector-illustration.jpg?b=1&s=612x612&w=0&k=20&c=SglMfLhVtHrJei2x9az3Fwqin6icpFD_2lgD0eqZVZA=',
};

const baseNavigation = [
  { name: 'Inicio', to: '/', current: true, auth: false, adminOnly: false },
  { name: 'Pronosticos', to: '/pronosticos', current: false, auth: true, adminOnly: false },
  { name: 'Resultados', to: '/resultados', current: false, auth: true, adminOnly: false },
  { name: 'Posiciones', to: '/posiciones', current: false, auth: true, adminOnly: false },
  // { name: 'Reports', href: '#', current: false },
  { name: 'Reglamento', to: '/reglamento', current: false, auth: false, adminOnly: false },
  { name: 'AdminPartidos', to: '/admin/partidos', current: false, auth: true, adminOnly: true },
];

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  // { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const queryClient = new QueryClient();

const getIsAdmin = (username: string) => username === "LorenzoR";

export default function App() {
  /*
  useEffect(() => {
    console.log('Component mounted');
    return () => {
      console.log('Component will be unmount')
    }
  }, [])//  # notice the empty array here, this is optional
  */

  const authData = useAuthStore((state: any) => state.auth);
  const token = authData?.signInUserSession?.idToken?.jwtToken;
  const username = authData?.attributes?.preferred_username;

  const isAdmin = getIsAdmin(username);

  const initialNavigation = baseNavigation.filter((o) => !!token ? true : o.auth === !!token).filter((o) => !isAdmin ? o.adminOnly !== true : true);

  const [navigation, setNavigation] = useState(initialNavigation);

  /*
  if (!!token) {
    console.log("hay token!");
    const homeIndex = baseNavigation.findIndex((o) => o.name === "Inicio");

    if (homeIndex) {
      baseNavigation[homeIndex].to = '/pronosticos';
    }
  }
  */

  if (!!token && JSON.stringify(navigation) !== JSON.stringify(initialNavigation)) {
    setNavigation(initialNavigation);
  }

  const onNavigationClick = (event: any) => {
    navigation.forEach((o) => {
      if (event.target.pathname !== o.to) {
        o.current = false;
      } else {
        o.current = true;
      }
    });

    setNavigation([...navigation]);
  };

  const title = (navigation.find((o) => o.current))?.name || "Dashboard";

  return (
    <>
      <BrowserRouter>
        {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
        <div className="min-h-full">
          <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 items-center justify-between">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8"
                          src="https://a2.espncdn.com/combiner/i?img=%2Fi%2Fleaguelogos%2Fsoccer%2F500%2F4.png"
                          alt="Prode Qatar 2022"
                        />
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.to}
                              onClick={(event) => onNavigationClick(event)}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'px-3 py-2 rounded-md text-sm font-medium'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        <button
                          type="button"
                          className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span className="sr-only">View notifications</span>
                          <BellIcon className="h-6 w-6" aria-hidden="true" />
                        </button>

                        {/* Profile dropdown */}
                        {false &&
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                              <span className="sr-only">Open user menu</span>
                              <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <a
                                      href={item.href}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                      )}
                                    >
                                      {item.name}
                                    </a>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      }
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button
                        className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                  <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        // onClick={(event) => onMobileNavigationClick(event)}
                        // href={'item.href'}
                        className={classNames(
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block px-3 py-2 rounded-md text-base font-medium'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <Link
                          key={item.name}
                          to={item.to}
                          onClick={(event) => onNavigationClick(event)}
                          className={classNames(
                            item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      </Disclosure.Button>
                    ))}
                  </div>
                  {false &&
                  <div className="border-t border-gray-700 pt-4 pb-3">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">{user.name}</div>
                        <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                      </div>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {userNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </div>
                  </div>
                  }
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <header className="bg-white shadow">
            <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">{title}</h1>
            </div>
          </header>
          <main>
            <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
              {/* Replace with your content */}
              <div className="px-4 py-6 sm:px-0">
                <div className="h-96 rounded-lg">
                  <QueryClientProvider client={queryClient}>
                    <Routes>
                      <Route path="/" element={<LoginPage />} />
                      <Route path="/voting" element={<VotingPage />} />
                      <Route path="/signUp" element={<SignUpPage />} />
                      <Route path="/logOut" element={<LoginPage logout={true} />} />
                      <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
                      <Route path="/pronosticos" element={<MatchesPage />} />
                      <Route path="/posiciones" element={<Positions />} />
                      <Route path="/resultados" element={<Results />} />
                      <Route path="/reglamento" element={<Rules />} />
                      <Route path="/admin/partidos" element={<AdminMatchesPage />} />
                    </Routes>
                  </QueryClientProvider>
                </div>
              </div>
              {/* /End replace */}
            </div>
          </main>
        </div>
      </BrowserRouter>
    </>
  )
}
